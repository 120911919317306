{
    class AflVaultFilterCriteriaItemController {
        static get $inject() {
            return ['VaultDataItemTypeConstant', '$filter', 'VaultFilterOperatorsConstant'];
        }

        constructor(VaultDataItemTypeConstant, $filter, VaultFilterOperatorsConstant) {
            this.ITEMTYPE = VaultDataItemTypeConstant;
            this.$filter = $filter;
            this.DATE = VaultFilterOperatorsConstant.DATE;
            this.OPERATOR = VaultFilterOperatorsConstant[this.criteria.itemType].OPERATOR;
        }

        showValue() {

            let caption = this.criteria.name;

            if (this.criteria && this.criteria.value) {

                switch (this.criteria.itemType) {
                    case this.ITEMTYPE.DATE:
                        caption = `${caption}${this.criteria.operator.CAPTION 
                            ? ' ' + this.criteria.operator.CAPTION + ' ' 
                            : ''}${this.$filter('date')(this.criteria.value, 'MM/dd/yyyy')}`;
                        break;
                    case this.ITEMTYPE.TEXT:
                    default:
                        caption = `${caption}${this.criteria.operator.CAPTION
                            ? ' ' + this.criteria.operator.CAPTION + ' '
                            : ''}${this.criteria.value}`;
                        break;
                }

            }

            return caption;
        }

        setValue(value, operator) {
            this.criteria.value = value;
            if (operator) {
                this.criteria.operator = operator;
            }
            this.onUpdate();
        }

        cancel() {
            delete this.criteria.value;
            delete this.criteria.operator;
        }

        close() {
            this.cancel();
            this.closeButtonClick({
                criteria: this.criteria
            });
        }
    }


    angular.module('adminApp')
        .component('aflVaultFilterCriteriaItem', {
            controller: AflVaultFilterCriteriaItemController,
            templateUrl: `/static/javascript/admin/afl-vault/afl-vault-filter-bar/afl-vault-filter-criteria-item\
/afl-vault-filter-criteria-item.html`,
            bindings: {
                criteria: '=',
                closeButtonClick: '&',
                onUpdate: '&',
                itemId: '='
            }
        })
}
